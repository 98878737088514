
import { defineComponent, inject, ref, nextTick } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import UiUpload from "@/components/UI/UploadAvatar";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import { useRouter } from "vue-router";
import qs from "qs";
export default defineComponent({
  name: "UserInfo",
  components: {
    UiInput,
    UiSelect,
    UiUpload,
  },
  setup() {
    const my = ref<any>(null);
    let flag: any = null;
    const router = useRouter();
    const isDisable = ref(false)

    axios
      .get("/M/User/Info", { params: { validate: true } })
      .then((res) => {
        if (res.data.success) {
          flag = res.data.obj.flag;
          res.data.obj.my.userSex = String(res.data.obj.my.userSex)
          my.value = res.data.obj.my;

          // if (my.value.realName) {
          //   isDisable.value = true
          // }
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log('err')
      });
    function back() {
      router.go(-1);
    }
    const userInfo = inject("userInfo") as any;
    function saveInfo() {
      console.log(userInfo);
      const userid = userInfo.id;
      if (my.value.userImg) {
        const arr = my.value.userImg.split(";base64,");
        if (arr[1]) {
          my.value.userImg = arr[1];
        }else{
          my.value.userImg = "";
        }
      } else {
        my.value.userImg = "";
      }
      const data = qs.stringify({
        flag: flag,
        id: userid,
        userName: my.value.userName,
        sex: my.value.userSex,
        userUnit: my.value.userUnit,
        mobile: my.value.mobile,
        // realName: my.value.realName,
        img64: my.value.userImg,
        userQq: my.value.userQq,
        nickName: my.value.nickName,
        personalSignature: my.value.personalSignature,
        userInfo: my.value.userInfo,
      });
      axios
        .post("/M/User/DoSaveMyInfo", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                // back();
                location.reload();
              },
            });
            nextTick(() => {
              router.push({
                name: "Mine"
              })
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }

    return {
      my,
      saveInfo,
      back,
      isDisable
    };
  },
});
